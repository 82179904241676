import * as Sentry from '@sentry/browser';

const configureSentry = (config) => {
  if (!config.sentryDSN) {
    return false;
  } else {
    const escapedAppHost = config.appHost.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    );
    const hostRegExp = new RegExp(
      '^' + window.location.protocol + '\\/\\/' + escapedAppHost
    );
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.resmioEnvironment,
      release: config.resmioRelease,
      ignoreErrors: ['Blocked a frame with origin', "Can't find variable: gmo"],
      integrations: [
        Sentry.functionToStringIntegration(),
        Sentry.globalHandlersIntegration({ onunhandledrejection: false }),
        Sentry.inboundFiltersIntegration(),
        Sentry.linkedErrorsIntegration(),
        Sentry.browserApiErrorsIntegration(),
        Sentry.browserTracingIntegration(),
      ],
      tracesSampleRate: config.sentryTracingEnabled ? 1.0 : 0,
      tracePropagationTargets: ['localhost', hostRegExp],
      autoSessionTracking: false,
      _experiments: {
        profilesSampleRate: config.sentryProfilingEnabled ? 1.0 : 0,
      },
    });
    if (config.user_id) {
      Sentry.setUser({
        email: config.default_email,
        username: config.username,
        id: config.user_id,
      });
    }
  }
};

export default configureSentry;

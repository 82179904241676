<template>
  <div class="custom-fields-container">
    <w-expandable-text
      v-if="state.facilityCustomText"
      class="custom-text"
      :text="state.facilityCustomText"
    ></w-expandable-text>
    <div v-if="showCustomFields" class="custom-fields">
      <div class="custom-fields-header">{{ txtHeader }}</div>
      <div
        v-for="(fieldObj, i) in state.facilityCustomFields"
        v-bind:key="i"
        v-bind:class="{
          'widget-contact-custom-field': true,
          'widget-contact-comment': !fieldObj.is_checkbox,
        }"
      >
        <b-field
          v-if="fieldObj.is_checkbox"
          v-bind:class="{
            'widget-contact-custom-field__checkbox': true,
            'widget-contact-custom-field__checkbox--checked':
              state.formValues.customFields[i],
          }"
        >
          <b-checkbox
            v-model="state.formValues.customFields[i]"
            v-bind:required="fieldObj.is_required"
            v-bind:disabled="state.isSavingBooking"
          >
            {{ fieldObj.name }}
            <span v-if="fieldObj.is_required" class="accented">*</span>
          </b-checkbox>
        </b-field>
        <w-input
          v-else
          v-model="state.formValues.customFields[i]"
          type="text"
          v-bind:required="fieldObj.is_required"
          v-bind:placeholder="fieldObj.name"
          v-bind:disabled="state.isSavingBooking"
          @keydown.native.enter="onCommentEnterPress"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';
import InputField from './common/FormInputField';
import ExpandableText from './common/ExpandableText';

export default {
  props: ['state'],
  components: {
    'w-input': InputField,
    'w-expandable-text': ExpandableText,
  },
  data: () => {
    return {
      txtHeader: i18n.gettext('RESERVATION DETAILS'),
    };
  },
  computed: {
    showCustomFields: function () {
      return (
        this.state.facilityCustomFields.length > 0 &&
        !this.state.isAddingWaitlist
      );
    },
  },
  methods: {
    onCommentEnterPress: function (e) {
      e.stopPropagation();
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.custom-fields-container {
  overflow-x: hidden;
  overflow-y: auto;

  padding: 0 1.5rem 2rem 1.5rem;
}

.custom-text {
  padding-bottom: 1rem;
}

.custom-fields {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 0.5px solid var(--theme-font-color-opacity-5);
  padding: 1rem;
  border-radius: 10px;
  color: var(--theme-font-color-opacity-5);

  @at-root #{$broadway} & {
    box-shadow: 0 0 5px 1px var(--theme-accent-color-opacity-5);
    border: none;
    border-radius: 20px;
    margin-top: 5px;
  }

  @at-root #{$glass} & {
    background: var(--theme-background-color-opacity-5);
    border: none;
  }
}

.widget-contact-custom-field__checkbox {
  margin-top: 0.75rem;

  &.widget-contact-custom-field__checkbox--checked {
    color: inherit;
  }
}
</style>

import { getHostname } from '../app/utils/url';

// we will eventually remove this file in favor of a request that returns this data
const config = resmioApp.config['src/config'];

class AppConfig {
  constructor() {
    this.isPreview =
      window.self !== window.top &&
      window.document.referrer.startsWith(window.location.origin);
    // sentry
    this.sentryDSN = config.sentryDSN;
    this.sentryTracingEnabled = config.sentryTracingEnabled;
    this.sentryProfilingEnabled = config.sentryProfilingEnabled;
    this.appHost = config.appHost;
    this.resmioRelease = config.resmioRelease;
    this.resmioEnvironment = config.resmioEnvironment;
    this.sentryUserName = config.username;
    this.sentryDefaultEmail = config.default_email;
    this.sentryUserId = config.user_id;

    this.fbAppId = config.fbAppId;
    this.fbChannelUrl = config.fbChannelUrl;

    this.language = config.languageCode;

    // facility related
    this.staticUrl = config.staticUrl;
    this.apiBaseUrl = `/v1/facility/${config.facility.id}`;
    this.facilityId = config.facility.id;
    this.facilityResourceUri = this.apiBaseUrl;
    this.facilityName = config.facility.name;
    this.facilityStreet = config.facility.street;
    this.facilityCity = config.facility.city;
    this.facilityZip = config.facility.zip_code;
    this.facilityCountry = config.facility.country;
    this.facilityPhone = config.facility.phone;
    this.facilityEmail = config.facility.email;
    this.preferredContactMethod = config.facility.preferred_contact_method;
    this.facilityTimeZone = config.facility.timezone;
    if (config.facility.website) {
      this.facilityWebsite = /^https?:\/\/.*$/.test(config.facility.website)
        ? config.facility.website
        : `http://${config.facility.website}`;
    }
    this.facilityOpeningHours = config.facility.opening_hours;
    this.facilityVisualOpeningHours = config.facility.visual_opening_hours;
    this.facilityClosedText = config.facility.widget_closed_text;
    this.facilityCustomText = config.facility.widget_custom_text;
    this.facilityNewsletterSignupText =
      config.facility.widget_newsletter_signup_text;
    this.facilityCustomFields = config.facility.widget_custom_fields || [];
    this.facilityNotAvailableText = config.facility.widget_not_available_text;
    this.facilityNotAvailableLink = config.facility.widget_not_available_link;
    this.facilityBookingRequestText =
      config.facility.widget_booking_request_text;
    this.facilityAutomaticallyConfirmBookings =
      config.facility.automatically_confirm_bookings;
    this.facilityAutomaticallyConfirmBookingsUntilGroupSize =
      config.facility.automatically_confirm_bookings_until_group_size;
    this.facilityBookingAutoConfirmationMaxGroupSize =
      config.facility.booking_auto_confirmation_max_group_size;
    this.facilityCurrency = config.facility.currency;
    this.facilityReachedBookingLimit = config.disabled;
    this.facilityMaxNum = config.facility.max_num;
    this.facilityMinNum = config.facility.min_num;
    this.facilityEnableOnlineWaitlist = config.facility.waitlist_enabled;
    this.facilityMinBookInAdvanceHours =
      config.facility.min_book_in_advance_minutes;
    this.facilityMaxBookInAdvanceDays =
      config.facility.max_book_in_advance_days;
    this.facilityHidePublicBranding = config.facility.hide_public_branding;
    this.facilityTermsConditions =
      config.facility.legal_notice_terms_conditions;
    this.facilityPrivacyPolicy = config.facility.legal_notice_privacy_policy;
    this.facilityLegalNotice = config.facility.legal_notice_imprint;
    this.facilityCancellationPolicy =
      config.facility.legal_notice_cancellation_policy;
    this.facilityCancelWithoutFees = config.facility.cancel_without_fees;
    this.facilityCancelWithoutFeesDays =
      config.facility.cancel_without_fees_days;
    this.isOrderbirdFacility = config.isOrderbirdFacility;
    this.anyRoomChoiceDisabled =
      config.facility.widget_any_room_choice_disabled;
    this.roomChoiceEnabled = config.facility.widget_room_choice_enabled;
    this.facilityBookingInterval = config.bookingInterval;
    this.facilityPromoTimes = config.facility.promo_times;
    this.facilityReservationEndTimeEnabled =
      config.facility.reservation_end_time_enabled;
    this.cancelWithoutFeesDays = config.facility.cancel_without_fees
      ? config.facility.cancel_without_fees_days
      : undefined;
    this.oneTimeFacilityToken = config.facility.one_time_facility_token;
    // facility deposit
    this.hasPaymentProvider =
      config.hasStripe || config.hasPayPal || config.hasBambora;
    this.bookingDepositEnabledByAddon = config.bookingDepositEnabledByAddon;
    this.facilityBookingDepositEnabled =
      config.facility.booking_deposits_enabled && this.hasPaymentProvider;
    this.facilityBookingDepositSepaEnabled =
      config.facility.stripe_sepa_enabled;
    this.facilityBookingDepositAmount = config.facility.deposit_amount;
    this.facilityBookingDepositPerGuest =
      config.facility.deposit_option === 'guest';
    this.facilityBookingDepositDescription =
      config.facility.deposits_widget_description;
    this.facilityBookingDepositReference = config.facility.deposit_reference;
    this.facilityBookingDepositGroupSize =
      config.facility.deposits_certain_group_size;
    this.facilityBookingDepositAllowedHours =
      config.facility.unique_deposit_hours;
    this.facilityBookingDepositDateRanges = config.facility.deposit_date_ranges;
    this.facilityResourceGroups = config.facility.resource_groups;
    this.paypalClientId = config.facility.paypal_client_id;
    this.stripePublishKey = config.facility.stripe_publish_key;
    this.stripeDelayedCharge = config.facility.stripe_delayed_charge;

    this.hasStripe = config.hasStripe;
    this.hasPayPal = config.hasPayPal;
    this.hasBambora = config.hasBambora;
    this.depositPayPalPeriodLimit = config.depositPayPalPeriodLimit;
    this.depositOption = config.facility.deposit_option;

    // security code option
    this.bookingSecurityCodeEnabled =
      config.facility.booking_security_code_enabled;

    // GET params
    this.resourceGroup =
      config.facility.resource_groups.find(
        (group) => String(group.id) === config.resourceGroup
      ) && config.resourceGroup;
    this.resourceGroupName =
      this.resourceGroup && decodeURIComponent(config.resourceGroupName);
    this.defaultName = config.name;
    this.defaultEmail = config.email;
    this.defaultPhone = config.phone;
    this.defaultComment = config.comment;
    this.defaultSubscribe = config.newsletterSubscribe;
    this.source = getHostname(config.source || window.location.href);
    this.nextAvailability = config.nextAvailability;
    this.backgroundColor = config.backgroundColor;
    this.fontSize = config.fontSize;
    this.textColor = config.color;
    this.accentColor = config.linkBackgroundColor;
    this.fullscreen = self === top || config.fullscreen;
    this.statusCode = config.statusCode;
    this.booking = config.booking;
    this.external = config.external;
    this.owner = config.owner;
    this.googleMapsApiUrl = config.googleMapsApiUrl;
    this.facebookLogin = config.facebookLogin === 'false' ? false : true;
    this.newsletterSignup = config.newsletterSignup === 'false' ? false : true;
    this.bookingOwnerStatus = config.bookingOwnerStatus;
    this.widgetStyle =
      (config.widgetCustomStylesEnabled || this.isPreview) &&
      ['glass', 'broadway', 'classic'].includes(config.style)
        ? config.style
        : 'classic';
    this.borderRadius = config.borderRadius || '10';
    this.facilityLogo =
      (config.widgetFacilityLogoEnabled || this.isPreview) && config.showLogo
        ? config.facility.logo
        : '';
    this.disableBranding = config.disableBranding;
    this.standardStyleOnly =
      !config.widgetCustomStylesEnabled && !this.isPreview;
    this.imageResizeService = config.imageResizeService;
    this.stripeSetupPaymentIntentId = config.stripeSetupPaymentIntentId;
    this.date = config.date;
  }
}

const appConfig = new AppConfig();
export default appConfig;
